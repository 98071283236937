import React from 'react';
import styled, { css } from 'styled-components';

import {
	ContentToRichText,
	getComponentSettings,
	getTextColorBasedOnBg,
	variableIsString,
} from 'libs/content';
import ListStyling, {
	ListWithIconsStyling,
	generateFirstOfTypeStyle,
	generateLastOfTypeStyle,
} from 'libs/SharedStyling';
import { AnimationWrap } from 'libs/animations';
import SanitizeHtml from 'libs/SanitizeHtml';
import Button from 'components/forms/Button';
import PopupButtons from 'components/naeringsservice/PopupButtons';
import BlankButton from 'components/forms/BlankButton';
import { PopupContent } from 'parts/form/Form';
import DownloadApp from 'components/mitt-nte/DownloadApp';

const ContentWrap = styled(AnimationWrap)`
	${ListStyling}
	${ListWithIconsStyling}
    margin-bottom: ${p =>
		(!p.$hasButtons && p.$nested && p.theme.spacing.desktop.medium) || '0'};
	margin-top: ${p => (p.$hasTitle && '10px !important') || '0'};
	text-align: ${p => (p.$centered === 'true' && 'center') || 'left'};

	max-width: ${p =>
		(p.$wrapperWidth === 'medium' && p.theme.widths.medium) ||
		p.theme.widths.default};

	${p => p.$firstelement && generateFirstOfTypeStyle(p.$firstelement)}
	${p => p.$lastelement && generateLastOfTypeStyle(p.$lastelement)}

	${p => p.$marginbottom && `margin-bottom: ${p.$marginbottom};`}

	${p =>
		p.theme.media.mediumOnly(css`
			margin-bottom: ${p =>
				(!p.$hasButtons &&
					p.$nested &&
					p.theme.spacing.tablet.medium) ||
				'0'};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			margin-bottom: ${p =>
				(!p.$hasButtons &&
					p.$nested &&
					p.theme.spacing.mobile.medium) ||
				'0'};
		`)}

	${p =>
		p.$wrapperWidth === 'medium' &&
		css`
			margin-left: auto;
			margin-right: auto;
		`}

	ul:not(.tags):not(.job-vacancies),
	ol {
		li {
			margin-bottom: ${p => p.theme.spacing.desktop.xxsmall};
		}
	}
	.gray-box {
		margin-left: 0;
		transform: none;
		float: left;
	}
	.max-width {
		padding: 0;
	}

	.button-popup,
	a[type='button'] {
		margin: ${p =>
			`${p.theme.spacing.desktop.xxsmall} ${p.theme.spacing.desktop.xsmall} 0
			0`};
	}

	p,
	li {
		color: ${p =>
			(p?.$textcolors.text && p.theme.colors[p.$textcolors.text]) ||
			p.theme.colors.grey900} !important;
	}

	p > a:not(__typename='ContentfulKomponentKnapp'),
	p > small > a:not(__typename='ContentfulKomponentKnapp') {
		color: ${p =>
			(p.$textcolors.link && p.theme.colors[p.$textcolors.link]) ||
			p.theme.colors.blue600} !important;
		&:hover {
			color: ${p =>
				(p.$textcolors.linkHover &&
					p.theme.colors[p.$textcolors.linkHover]) ||
				p.theme.colors.blue800} !important;
		}
	}

	${p =>
		p.$centered === 'true' &&
		css`
			ul:not(.tags):not(.job-vacancies),
			ol {
				margin: 0 auto 20px !important;
				width: max-content;
			}
		`}

	.spacing {
		padding-bottom: ${p => p.theme.spacing.desktop.large};
		${p =>
			p.theme.media.mediumOnly(css`
				padding-bottom: ${p => p.theme.spacing.tablet.large};
			`)}
		${p =>
			p.theme.media.smallOnly(css`
				padding-bottom: ${p => p.theme.spacing.mobile.large};
			`)}
		&:last-of-type {
			padding-bottom: 0 !important;
		}
	}

	${p =>
		(p.$componentindex === 0 || !!p.$isfirstheading) &&
		css`
			> div > p:first-of-type,
			p.large {
				${p =>
					p.theme.media.large(css`
						font-size: 20px;
						line-height: 32px;
					`)}
			}
		`}
`;

const Inner = styled.div`
	${p =>
		(p.$hasChildren &&
			css`
				margin-bottom: ${p.theme.spacing.desktop.medium};
				${p =>
					p.theme.media.mediumOnly(css`
						margin-bottom: ${p.theme.spacing.tablet.medium};
					`)}
				${p =>
					p.theme.media.smallOnly(css`
						margin-bottom: ${p.theme.spacing.mobile.medium};
					`)}
			`) ||
		css`
			margin-bottom: 0;
		`};

	p {
		white-space: pre-line;
	}

	.intro-text {
		max-width: ${p => p.theme.widths.medium} !important;
		margin: ${p => (p.$centered === 'true' && '0 auto') || '0'};
	}

	figure {
		margin-bottom: ${p => p.theme.spacing.desktop.small};
		${p =>
			p.theme.media.mediumOnly(css`
				margin-bottom: ${p.theme.spacing.tablet.small};
			`)}
		${p =>
			p.theme.media.smallOnly(css`
				margin-bottom: ${p.theme.spacing.mobile.small};
			`)}
	}

	.component__stats > div {
		padding: 0;
	}

	.heading {
		margin: 0;
		padding-bottom: ${p => p.theme.spacing.desktop.xxsmall};
		&:not(:first-of-type) {
			padding-top: ${p => p.theme.spacing.desktop.xsmall};
		}
	}

	.component__image .component__image--img {
		padding-bottom: ${p => p.theme.spacing.desktop.small};
		&:not(:first-child) .component__image--img {
			padding-top: ${p => p.theme.spacing.desktop.xsmall};
		}
	}

	ol:not(:first-child) {
		margin-top: ${p => p.theme.spacing.desktop.xsmall};
	}

	> button:not(:first-child) {
		margin-bottom: ${p => p.theme.spacing.desktop.xsmall};
	}

	:last-child {
		margin-bottom: 0 !important;
	}
`;

/**
 * Represents a component with a short text / child-component and optional buttons
 * @param {object} text - The text for the component.
 * @param {array} buttons - The buttons for the component.
 * @param {React.Component} children - The child components
 * @param {array} buttons - The buttons for the component.
 * @param {string} transition - Wheter the component should have animation or not
 * @param {boolean} transitionEach - Wheter the component should have animation on each text or not
 * @param {...object} props - The rest of the properties for the component
 */
export default function Text({
	text = '' || {},
	buttons = [],
	children = null,
	componentSettings = {},
	transitions = 'false',
	transitionEach = true,
	...props
}) {
	// Get correct text-colors based on bg-color
	const textcolors = getTextColorBasedOnBg(
		props?.bg || componentSettings?.bg,
		componentSettings?.shadow
	);

	const hasText =
		!!text?.raw ||
		!!text?.childMarkdownRemark ||
		(!!text && variableIsString(text)) ||
		props?.buttons?.length > 0 ||
		componentSettings?.showappdownloadbtns === 'true';

	return (
		<>
			<ContentWrap
				delay={2}
				className="text"
				doanimate={transitions && transitionEach}
				direction="down"
				$nested={props?.nested || false}
				$textcolors={textcolors}
				$componentindex={props?.componentindex}
				$isfirstheading={props?.isfirstheading}
				$hasTitle={
					!!props?.title && componentSettings?.hidetitle !== 'true'
				}
				$marginbottom={props?.marginBottom}
				$hasButtons={buttons?.length > 0}
				$wrapperWidth={props?.wrapperWidth || 'large'}
				$centered={componentSettings?.centered}
				$checkicon={componentSettings?.checkicon}
				$xmarkicon={componentSettings?.xmarkicon}>
				{hasText && (
					<Inner
						$hasChildren={!!children}
						$centered={componentSettings?.centered}>
						<TextComponent text={text} />
					</Inner>
				)}
				{children}
			</ContentWrap>

			<Buttons
				buttons={buttons}
				settings={componentSettings}
				transitions={transitions}
				transitionEach={transitionEach}
				hasText={!!text || !!children}
				{...props}
			/>
		</>
	);
}

const TextWrap = styled.div`
	> div {
		margin: ${p => `0 0 ${p.theme.spacing.desktop.xsmall}`} !important;
		transform: none !important;
		float: none;
		width: 100%;
		padding: 0;
		&:not(.gray-box) {
			padding: 0 !important;
		}
	}
`;

/**
 * Represents the text component, which can be a rich-text, markdown, or plain text
 * @param {object} text - The text for the component
 * @param {boolean} isfirstheading - Wheter the title above the text is the first heading or not
 * @returns {React.Component} - The text component
 **/
export function TextComponent({ text }) {
	if (!text) {
		return null;
	}

	if (text?.raw) {
		return <ContentToRichText data={text} />;
	}

	if (text?.childMarkdownRemark?.html) {
		return <SanitizeHtml html={text} className="intro-text" />;
	}

	if (text && variableIsString(text)) {
		return (
			<TextWrap className="intro-text">
				<p>{text}</p>
			</TextWrap>
		);
	}

	if (
		text?.childMarkdownRemark?.rawMarkdownBody &&
		variableIsString(text?.childMarkdownRemark?.rawMarkdownBody)
	) {
		return (
			<TextWrap>
				<p>{text?.childMarkdownRemark?.rawMarkdownBody}</p>
			</TextWrap>
		);
	}

	return null;
}

const ButtonsWrapper = styled.div`
	text-align: ${p => p.alignment || 'left'};
	margin-top: ${p =>
		(!p.$hasText && p.theme.spacing.desktop.xxsmall + '!important') ||
		p.theme.spacing.desktop.xsmall};
	${p =>
		p.theme.media.mediumDown(css`
			margin-top: ${p =>
				(!p.$hasText &&
					p.theme.spacing.tablet.xxsmall + '!important') ||
				p.theme.spacing.tablet.small};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: ${p =>
				(!p.$hasText &&
					p.theme.spacing.mobile.xxsmall + '!important') ||
				p.theme.spacing.mobile.small};
		`)}
	button,
	a {
		margin: ${p =>
			`${p.theme.spacing.desktop.xxsmall} ${p.theme.spacing.desktop.xsmall} 0
			0`};
	}
`;

/**
 * Represents the button component
 * @param {object} button - The button object
 * @param {object} settings - The settings for the button
 * @param {boolean} transition - Wheter the component should have animation or not
 * @param {boolean} transitionEach - Wheter the component should have animation on each button or not
 * @param {boolean} hasText - Wheter the parent-component has text or not
 * @param {...object} props - The rest of the properties for the button
 * @returns {React.Component} - The button component
 */
function Buttons({
	buttons,
	settings,
	transitions,
	transitionEach,
	hasText = true,
	...props
}) {
	// If page type is "naeringsservice" return the popup buttons for that page
	if (props?.pagesettings?.pageType === 'naeringsservice') {
		return (
			<AnimationWrap
				className="buttons"
				doanimate={transitions && transitionEach}
				delay={3}
				direction="up">
				<PopupButtons
					style={{ marginTop: '30px' }}
					centered={settings?.centered}
				/>
			</AnimationWrap>
		);
	}

	if (settings?.showappdownloadbtns === 'true') {
		return (
			<AnimationWrap
				className="buttons"
				doanimate={transitions && transitionEach}
				delay={3}
				direction="up">
				<DownloadApp belowtext={hasText ? 'true' : 'false'} />
			</AnimationWrap>
		);
	}

	// If no buttons, return null
	if (!buttons?.length > 0) return null;

	// Otherwise return the buttons
	return (
		<AnimationWrap
			doanimate={transitions && transitionEach}
			delay={3}
			direction="up">
			<ButtonsWrapper
				className="buttons"
				data-cy="buttons-wrapper"
				$hasText={hasText}
				alignment={
					(settings?.centered === 'true' && 'center') ||
					(settings?.layout === 'fullwidth' &&
						settings?.imagePlacement) ||
					'left'
				}>
				{buttons?.map((btn, i) => (
					<SingleButton button={btn} settings={settings} key={i} />
				))}
			</ButtonsWrapper>
		</AnimationWrap>
	);
}

/**
 * Represents a button component
 * @param {object} button - The button object
 * @param {object} settings - The settings for the button
 * @returns {React.Component} - The button component
 */
export function SingleButton({ button, settings }) {
	if (!button) return null;

	// Define the button component
	const BtnComponent =
		(settings?.linkelement === 'text' && BlankButton) || Button;

	// If is a popup form
	if (
		button?.internal?.type === 'ContentfulKomponentSkjema' &&
		!!(button?.typeformUrl || button?.segmentForm)
	) {
		const componentSettings = {
			...getComponentSettings({
				settings: button?.settings,
			}),
			formHeights: {
				desktop: button?.formHeightDesktop || 750,
				mobile: button?.formHeightMobile || 520,
			},
		};
		return <PopupContent {...button} {...componentSettings} />;
	}

	// If is a normal button
	return (
		<BtnComponent {...button} data-cy="button">
			{button?.text}
		</BtnComponent>
	);
}
